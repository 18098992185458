import '@splidejs/react-splide/css/core'
import { NextPage } from 'next'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import React, { ReactElement, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import Routes from 'src/components/Routes'

import GoogleTagManager from '@/components/GoogleTagManager'
import Notifications from '@/components/Notifications'
import ReferralProgram from '@/components/ReferralProgram'
import ServerWrapper from '@/components/ServerWrapper'

import SEO from '../../next-seo.config'

import { Root } from '@/layouts'
import { wrapper } from '@/store'
import '@/styles/globals.scss'

const isServer = typeof window === 'undefined'

const isAnalyticsEnabled = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, ...rest }: AppPropsWithLayout) {
  const { t } = useTranslation()

  const { store, props } = wrapper.useWrappedStore(rest)

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <div suppressHydrationWarning>
      <Provider store={store}>
        <ServerWrapper isServer={isServer} component={BrowserRouter}>
          <ParallaxProvider>
            <DefaultSeo
              title={t('head:title') as string}
              description={t('head:description') as string}
              {...SEO}
            />
            {isAnalyticsEnabled && <GoogleTagManager />}
            <Root>
              <ServerWrapper isServer={isServer} component={Routes}>
                {getLayout(<Component {...props.pageProps} />)}
              </ServerWrapper>
            </Root>
          </ParallaxProvider>
          <ReferralProgram />
          <Notifications />
        </ServerWrapper>
      </Provider>
    </div>
  )
}

export default appWithTranslation(App)
