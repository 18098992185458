import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, ReactNode, useEffect, useMemo } from 'react'
import { Routes as ReactRoutes, Route, useLocation } from 'react-router-dom'

import getPathClear from '@/utils/getPathClear'
import ScrollToAnchor from '@/utils/scrollToAnchor'

import { useRouter as useClientRouter } from '@/bus/router'
import { routes } from '@/config'
import { Main } from '@/layouts'

const Networks = dynamic(
  () => import('@/contents/Networks').then(({ Networks }) => Networks),
  {
    ssr: false
  }
)

const PartnerProgram = dynamic(
  () =>
    import('@/contents/PartnerProgram').then(
      ({ PartnerProgram }) => PartnerProgram
    ),
  {
    ssr: false
  }
)

const Home = dynamic(() => import('@/contents/Home').then(({ Home }) => Home), {
  ssr: false
})

const Network = dynamic(
  () => import('@/contents/Network').then(({ Network }) => Network),
  {
    ssr: false
  }
)

const Login = dynamic(
  () => import('@/contents/Login').then(({ Login }) => Login),
  {
    ssr: false
  }
)

const Register = dynamic(
  () => import('@/contents/Register').then(({ Register }) => Register),
  {
    ssr: false
  }
)

const Recovery = dynamic(
  () => import('@/contents/Recovery').then(({ Recovery }) => Recovery),
  {
    ssr: false
  }
)

const Texts = dynamic(
  () => import('@/contents/Texts').then(({ Texts }) => Texts),
  {
    ssr: false
  }
)

const Error404 = dynamic(
  () => import('@/contents/Error404').then(({ Error404 }) => Error404),
  {
    ssr: false
  }
)

type RouteType = {
  path: string
  element: ReactNode
}

const ROUTES: RouteType[] = [
  {
    path: routes.HOME,
    element: <Home />
  },
  {
    path: routes.LOGIN,
    element: <Login />
  },
  {
    path: routes.NETWORKS,
    element: <Networks />
  },
  {
    path: `${routes.NETWORKS}/:id`,
    element: <Network />
  },
  {
    path: routes.PARTNERS,
    element: <PartnerProgram />
  },
  {
    path: routes.LOGIN,
    element: <Login />
  },
  {
    path: routes.REGISTER,
    element: <Register />
  },
  {
    path: routes.RECOVERY,
    element: <Recovery />
  },
  {
    path: routes.CONTACTS,
    element: <Texts slug={'contacts'} />
  },
  {
    path: routes.HELP,
    element: <Texts slug={'faq'} />
  },
  {
    path: routes.USER_AGREEMENTS,
    element: <Texts slug={'oferta'} />
  },
  {
    path: routes.OFERTA_SPARTA,
    element: <Texts slug={'oferta_sparta'} />
  },
  {
    path: routes.PRIVACY_POLICY,
    element: <Texts slug={'privacy-policy'} />
  },
  {
    path: routes.PROMISED_PAYMENT_TERMS,
    element: <Texts slug={'promised-payment-terms'} />
  },
  {
    path: routes.REFUND,
    element: <Texts slug={'refund'} />
  },
  {
    path: routes.TIKTOK_RULES,
    element: <Texts slug={'tiktok-rules'} />
  }
]

const Routes: FC<PropsWithChildren> = ({ children }) => {
  const { setRouter } = useClientRouter()

  const location = useLocation()
  const router = useRouter()

  useEffect(() => {}, [])

  useEffect(() => {
    if (location?.hash?.length) {
      return
    }
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant'
    })
  }, [location, location.pathname])

  const isOriginPath = useMemo(
    () =>
      !location ||
      getPathClear(router.asPath) === getPathClear(location?.pathname),
    [router, location]
  )

  useEffect(() => {
    setRouter({
      location: { ...location, pathname: getPathClear(location.pathname) }
    })
  }, [location, setRouter])

  if (isOriginPath) {
    return <>{children}</>
  }

  return (
    <Main>
      <ReactRoutes>
        {ROUTES.map(({ path, element }) => (
          <Route
            key={path}
            path={`${path.replace(/^\//, '')}`}
            element={element}
          />
        ))}
        <Route path={`:lang`}>
          {ROUTES.map(({ path, element }) => (
            <Route
              key={path}
              path={`${path.replace(/^\//, '')}`}
              element={element}
            />
          ))}
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </ReactRoutes>
      <ScrollToAnchor />
    </Main>
  )
}

export default Routes
