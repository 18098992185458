//libraries
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import FooterCopyright from '@/components/FooterCopyright'
import FooterLogo from '@/components/FooterLogo'
import FooterMenu from '@/components/FooterMenu/FooterMenu'
import { FooterMenuProps } from '@/components/FooterMenu/FooterMenuProps'
import Link from '@/components/Link'

import events from '@/utils/events'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Footer.module.scss'
import { routes } from '@/config'

interface FooterProps extends DefaultComponentProps {
  animation?: boolean
}
export const Footer: FC<FooterProps> = ({
  className = '',
  animation = true
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation('footer')

  const menu: FooterMenuProps[] = t('menu', { returnObjects: true })

  const toggleAnimateContent = useCallback(
    (state: boolean) => {
      setAnimationStart(state)
    },
    [setAnimationStart]
  )

  useEffect(() => {
    events.on('waypoint::8::enter', () => toggleAnimateContent(true))

    return () => {
      events.off('waypoint::8::enter', () => toggleAnimateContent(true))
    }
  }, [])

  useEffect(() => {
    if (animation) {
      return
    }
    toggleAnimateContent(true)
  }, [animation])

  return (
    <Waypoint
      topOffset={'20%'}
      bottomOffset={'20%'}
      onEnter={() => events.emit('waypoint::8::enter')}
    >
      <footer
        className={clsx(styles['footer'], className, {
          [styles['footer_animation-start']]: animationStart
        })}
      >
        <div className={styles['footer__content']}>
          <div className={styles['footer__column']}>
            <FooterLogo className={styles['footer__logo']} />
            <FooterCopyright />
            <div className={styles['footer__arda-digital']}>
              <Link
                href={routes.ARDA_DIGITAL}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <img
                  className={styles['footer__arda-digital-img']}
                  src={'/images/arda-digital/logo.svg'}
                  alt={routes.ARDA_DIGITAL}
                />
              </Link>
            </div>
          </div>
          {menu.map(({ heading, items, id }: FooterMenuProps) => (
            <div key={id} className={styles['footer__column']}>
              <FooterMenu
                heading={heading}
                items={items}
                className={styles['footer__menu']}
              />
            </div>
          ))}
        </div>
      </footer>
    </Waypoint>
  )
}
